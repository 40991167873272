<template>
  <v-container
    id="login"
    fluid
  >
    <v-row
      justify="center"
      align="center"
    >
      <v-col
        cols="12"
        md="6"
        class="d-none d-md-block pa-0"
      >
        <v-img
          height="100vh"
          cover
          class="d-flex align-center justify-center"
          src="@/assets/login_images/left-img.png"
        >
          <v-img
            contain
            width="50%"
            class="d-flex align-center justify-center mx-auto"
            src="@/assets/logo-big.svg"
          />
        </v-img>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="d-flex align-center justify-center mx-auto pa-0"
      >
        <div>
          <v-row
            justify="center"
            class="py-2"
          >
            <v-col
              cols="11"
              md="11"
              lg="10"
              xl="10"
            >
              <v-img
                contain
                width="115px"
                class=""
                src="@/assets/logo.svg"
              />
            </v-col>
            <v-col
              cols="11"
              md="11"
              lg="10"
              xl="10"
            >
              <div class="font-weight-regular text-h6">
                ¡ Bienvenido !
              </div>
              <div class="font-weight-negrita text-h5">
                Iniciar sesión
              </div>
            </v-col>
          </v-row>
          <custom-form
            validate
            :form="form"
            width-md="11"
            :loading="loading"
            width-lg="10"
            width-xl="10"
            @submit="initialize"
            @recover="init('recover')"
            @back="init('login')"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import { setCookie, removeCookie } from '@/util/cookies'
  import { get, call } from 'vuex-pathify'

  export default {
    name: 'Login',
    components: { },
    data () {
      return {

        form: {},
        formSelected: 'login',
        loading: false,
      }
    },
    computed: {
      query: get('route/query'),
    },
    async mounted () {
      window.localStorage.clear()
      await removeCookie()
      this.init('login')
    },
    methods: {
      getUserInfo: call('user/getUserInfo'),
      init (form) {
        this.showMsg = false
        this.loading = true
        this.formSelected = form
        this.form = require(`@/jsons/login/${form}.json`)
        this.loading = false
      },
      async initialize (value) {
        this.loading = true

        if (value) {
          const { response, data } = value
          const isUser = response?.proveedor?.id_proveed && response?.token
          const isAdmin = response?.admin?.cuit && response?.token
          const isClienteInterno = response?.cliente_interno?.id_cliente && response?.token

          if (isUser || isAdmin || isClienteInterno) {
            await setCookie({ ...response, token_type: 'Bearer', cuit: data?.cuit })
            this.getUserInfo()
            if (isUser) {
              this.$router.push({ path: this.query?.redirect || '/' }).catch(error => {
                console.log(error.message)
              })
            }
            if (isAdmin) {
              this.$router.push({ path: '/usuarios' }).catch(error => {
                console.log(error.message)
              })
            }
            if (isClienteInterno) {
              const idDestino = response.cliente_interno.unidad && response.cliente_interno.unidad !== '' ? `/${response.cliente_interno.unidad}` : ''
              this.$router.push({ path: `/cliente/${response.cliente_interno.id_cliente}${idDestino}` }).catch(error => {
                console.log(error.message)
              })
            }
          }
        }
        // El documento y la contraseña especificada son incorrectas
        if (this.formSelected === 'recover') {
          this.formSelected = 'recoverFinished'
        }
        this.loading = false
      },

    },
  }
</script>

<style scoped lang="scss">
#login-wrapper{
  height: 100vh;
  .main-card{
    overflow: hidden;
  }
}
</style>
